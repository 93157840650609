import { HttpErrorResponse } from '@angular/common/http';
import { inject, InjectionToken } from '@angular/core';
import { lastValueFrom, map, Observable, Subject } from 'rxjs';
import {
  Attachment,
  AttachmentService,
  SourceDocumentAttachment,
  SourceDocumentType,
  StateAttachmentUtils,
} from '@dougs/core/files';
import { X_HEADER } from '@dougs/core/interceptors';
import { JobService } from '@dougs/core/job';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { toPromise } from '@dougs/core/utils';
import { ConfirmationModalComponent, FlashMessagesService, ModalService } from '@dougs/ds';
import { EcommerceSaleStateService, ShopifyHttp } from '@dougs/ecommerce/shared';
import { AccountingSearch, Breakdown, Operation, OperationPost } from '@dougs/operations/dto';
import { UserStateService } from '@dougs/user/shared';
import { OperationHttpService, SourceDocumentAttachmentAndJob } from '../http/operation.http';
import { OperationService, OperationsSearchService } from '../services';
import { AssociationService } from '../services/association.service';
import { OperationsEventsService } from '../services/operations-events.service';
import { OptimisticSourceDocumentOperationService } from '../services/optimistic-source-document-operation.service';

export const OPERATION_STATE_TOKEN: InjectionToken<AbstractOperationsStateService<any>> = new InjectionToken<
  AbstractOperationsStateService<any>
>('OPERATION STATE TOKEN');

export abstract class AbstractOperationsStateService<T> extends StateService<
  T & {
    operations: Operation[];
  }
> {
  protected readonly LIMIT = 40;
  protected OFFSET = 0;
  protected search?: AccountingSearch;
  protected isValidatingOperation = false;

  // A BOUGER
  private readonly isTaxReportLoading: Subject<boolean> = new Subject<boolean>();
  readonly isTaxReportLoading$: Observable<boolean> = this.isTaxReportLoading.asObservable();

  protected operationHttpService: OperationHttpService = inject(OperationHttpService);
  protected attachmentService: AttachmentService<Operation> = inject(AttachmentService<Operation>);
  protected readonly logger: LoggerService = inject(LoggerService);
  protected readonly operationService: OperationService = inject(OperationService);
  protected readonly associationService: AssociationService = inject(AssociationService);
  protected operationsEventsService: OperationsEventsService = inject(OperationsEventsService);
  protected operationsSearchService: OperationsSearchService = inject(OperationsSearchService);
  protected flashMessagesService: FlashMessagesService = inject(FlashMessagesService);
  protected readonly shopifyHttp: ShopifyHttp = inject(ShopifyHttp);
  protected jobService: JobService = inject(JobService);
  protected readonly modalService: ModalService = inject(ModalService);
  protected readonly userStateService: UserStateService = inject(UserStateService);
  protected readonly ecommerceSaleStateService: EcommerceSaleStateService = inject(EcommerceSaleStateService);
  protected readonly optimisticSourceDocumentOperationService: OptimisticSourceDocumentOperationService = inject(
    OptimisticSourceDocumentOperationService,
  );

  protected constructor() {
    super();
    this.operationsEventsService.updateOperation$.subscribe((operation: Operation) => {
      this.updateOperationState(operation);
    });
    this.operationsEventsService.addOperation$.subscribe(
      (operation: Operation) => this.shouldOperationBeAddedToState(operation) && this.addOperationState(operation),
    );
    this.operationsEventsService.removeOperation$.subscribe((operation: Operation) =>
      this.removeOperationState(operation),
    );
  }

  public operations$: Observable<Operation[]> = this.select((state) => state.operations);

  public abstract refreshOperations(...args: any): Promise<any>;

  protected abstract shouldOperationBeAddedToState(operation: Operation): boolean;

  public async updateOperation(
    operation: Operation,
    breakdown?: Breakdown,
    force?: boolean,
  ): Promise<Operation | null> {
    try {
      const updatedOperation: Operation = await lastValueFrom(
        this.operationHttpService.updateOperation(operation, breakdown, force),
      );
      this.operationsEventsService.propagateUpdateOperation(updatedOperation);
      return updatedOperation;
    } catch (e) {
      return await this.handleUpdateError(e, operation, breakdown);
    }
  }

  public async updateOperationWithoutStateUpdate(
    operation: Operation,
    breakdown?: Breakdown,
    force?: boolean,
  ): Promise<Operation | null> {
    try {
      const updatedOperation: Operation = await lastValueFrom(
        this.operationHttpService.updateOperation(operation, breakdown, force),
      );
      return updatedOperation;
    } catch (e) {
      return await this.handleUpdateError(e, operation, breakdown);
    }
  }

  public async autoCategorizeOperations(operation: Operation): Promise<void> {
    try {
      const operationsUpdated: Operation[] = await lastValueFrom(
        this.operationHttpService.autoCategorizeOperations(operation.companyId, operation.id),
      );

      operationsUpdated.forEach((operationUpdated) => {
        this.operationsEventsService.propagateUpdateOperation(operationUpdated);
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  public async autoAssociateOperations(operation: Operation): Promise<void> {
    try {
      const operationsUpdated: Operation[] = await lastValueFrom(
        this.operationHttpService.autoAssociateOperations(operation.companyId, operation.id),
      );

      operationsUpdated.forEach((operationUpdated) => {
        this.operationsEventsService.propagateUpdateOperation(operationUpdated);
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  public async validateOperation(operation: Operation): Promise<Operation | null> {
    try {
      const updatedOperation: Operation = await lastValueFrom(this.operationHttpService.updateOperation(operation));
      this.operationsEventsService.propagateValidateOperation(updatedOperation);
      return updatedOperation;
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        this.showErrorMessage(e);
      }
      this.logger.error(e);
      return null;
    }
  }

  public async createOperation(
    companyId: number,
    operation: OperationPost,
    files: File[] | undefined = undefined,
  ): Promise<Operation | null> {
    try {
      let operationCreated: Operation = await lastValueFrom(
        this.operationHttpService.createOperation(companyId, operation),
      );
      this.operationsEventsService.propagateAddOperation(operationCreated);
      if (files) {
        operationCreated = await toPromise(this.operationHttpService.uploadSourceDocuments(operationCreated, files));
        this.operationsEventsService.propagateUpdateOperation(operationCreated);
      }
      return operationCreated;
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        this.showErrorMessage(e);
      }
      this.logger.error(e);
      return null;
    }
  }

  public async removeOperation(operation: Operation): Promise<boolean> {
    try {
      await lastValueFrom(this.operationHttpService.deleteOperation(operation));
      this.operationsEventsService.propagateRemoveOperation(operation);
      return true;
    } catch (e) {
      if (e instanceof HttpErrorResponse) {
        this.showErrorMessage(e);
      }
      this.logger.error(e);
      return false;
    }
  }

  public async uploadSourceDocuments(operation: Operation, files: File[], type?: SourceDocumentType): Promise<void> {
    try {
      const attachmentsMap: Map<string, File> = new Map<string, File>();
      const temporarySourceDocumentAttachments: SourceDocumentAttachment[] =
        StateAttachmentUtils.getModelWithTempSourceDocument(
          files,
          attachmentsMap,
          operation.companyId,
          operation.id,
          type,
        );
      this.optimisticSourceDocumentOperationService.addTmpUuids(operation.id, Array.from(attachmentsMap.keys()));
      const operationWithTempSourceDocuments: Operation = this.attachmentService.addSourceDocumentAttachment(
        operation,
        temporarySourceDocumentAttachments,
      );

      this.operationsEventsService.propagateUpdateOperation(operationWithTempSourceDocuments);

      const operationUpdated: Operation = await toPromise(
        this.operationHttpService.uploadSourceDocuments(operation, Array.from(files), type),
      );
      const operationUpdatedWithNewSourceDocumentAttachments: Operation = {
        ...operationUpdated,
        sourceDocumentAttachments: (operationUpdated.sourceDocumentAttachments || []).map((sda) =>
          !operationWithTempSourceDocuments.sourceDocumentAttachments?.find((tmpSda) => tmpSda.id === sda.id)
            ? {
                ...sda,
                sourceDocument: {
                  ...sda.sourceDocument,
                  hasBeenUploadedNow: true,
                },
              }
            : sda,
        ),
      };
      this.optimisticSourceDocumentOperationService.removeTmpUuids(operation.id, Array.from(attachmentsMap.keys()));
      this.operationsEventsService.propagateUpdateOperation(operationUpdatedWithNewSourceDocumentAttachments);
    } catch (e) {
      this.optimisticSourceDocumentOperationService.clearTmpUuids(operation.id);
      this.logger.error(e);
      this.operationsEventsService.propagateUpdateOperation(operation);
    }
  }

  public async refreshOperationById(companyId: number, operationId: number): Promise<Operation> {
    const updatedOperation: Operation = await lastValueFrom(
      this.operationHttpService.getOperationById(companyId, operationId),
    );
    this.operationsEventsService.propagateUpdateOperation(updatedOperation);
    return updatedOperation;
  }

  public async searchOperations(companyId: number, search: AccountingSearch): Promise<void> {
    this.search = {
      ...this.search,
      ...search,
    };

    const hasCurrentSearch: boolean = this.operationsSearchService.setCurrentSearch(this.search);
    if (!hasCurrentSearch) {
      this.search = undefined;
    }
    // Return lastValueFrom ???
    await this.refreshOperations(companyId);
  }

  public async buildBreakdowns(operation: Operation, linesData: string): Promise<Operation | null> {
    try {
      const operationUpdated: Operation = await lastValueFrom(
        this.operationHttpService.buildBreakdowns(operation.companyId, operation.id, linesData),
      );

      this.operationsEventsService.propagateUpdateOperation(operationUpdated);

      return operationUpdated;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  public async attachSourceDocument(
    operation: Operation,
    sourceDocumentId: number,
    sourceDocumentType?: SourceDocumentType,
  ): Promise<Operation | null> {
    try {
      const operationUpdated: Operation = await lastValueFrom(
        this.operationHttpService.attachSourceDocument(operation, sourceDocumentId, sourceDocumentType),
      );

      this.operationsEventsService.propagateUpdateOperation(operationUpdated);

      return operationUpdated;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  public async detachSourceDocument(
    operation: Operation,
    sourceDocumentAttachmentId: number,
  ): Promise<Operation | null> {
    try {
      const operationUpdated: Operation = await lastValueFrom(
        this.operationHttpService.detachSourceDocument(operation, sourceDocumentAttachmentId),
      );

      this.operationsEventsService.propagateUpdateOperation(operationUpdated);

      return operationUpdated;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  public async refreshShopifyDispatchOperation(
    companyId: number,
    salesChannelId: number,
    operationId: number,
  ): Promise<void> {
    try {
      await this.deleteDispatchOperationBreakdowns(companyId, operationId);
      await this.refreshShopifyBreakdowns(companyId, salesChannelId, operationId);
      await this.refreshOperationById(companyId, operationId);
    } catch (e) {
      this.logger.error(e);
    }
  }

  public async deleteDispatchOperationBreakdowns(companyId: number, operationId: number): Promise<void> {
    try {
      await lastValueFrom(this.operationHttpService.deleteDispatchOperationBreakdowns(companyId, operationId));
    } catch (e) {
      this.logger.error(e);
    }
  }

  private async refreshShopifyBreakdowns(
    companyId: number,
    salesChannelId: number,
    operationId: number,
  ): Promise<void> {
    return lastValueFrom(this.shopifyHttp.refreshDispatch(companyId, salesChannelId, operationId));
  }

  //////////// DEBUT ZONE A BOUGER //////////////////
  async uploadAmazonTaxReport(operation: Operation, file: File): Promise<void> {
    this.isTaxReportLoading.next(true);
    try {
      const sourceDocumentAttachmentAndJob: SourceDocumentAttachmentAndJob = await lastValueFrom(
        this.operationHttpService.postAmazonTaxReportOperationFile(operation, file),
      );

      const newOperation: Operation = {
        ...operation,
        sourceDocumentAttachments: [
          ...(operation.sourceDocumentAttachments || []),
          sourceDocumentAttachmentAndJob.attachment,
        ],
      };

      this.updateOperationState(newOperation);
      const job = sourceDocumentAttachmentAndJob.job;
      await lastValueFrom(this.jobService.handleJob(job));
      await this.refreshOperationById(operation.companyId, operation.id);
      this.isTaxReportLoading.next(false);
    } catch (e) {
      this.isTaxReportLoading.next(false);
      this.logger.error(e);
      this.flashMessagesService.show("Le fichier n'a pas pu être chargé. Nous sommes prévenus.", {
        type: 'error',
        timeout: 5000,
      });
      await this.refreshOperationById(operation.companyId, operation.id);
      this.logger.error(e);
    }
  }

  //////////// FIN ZONE A BOUGER //////////////////

  public propagateValidateOperation(operation: Operation): void {
    this.operationsEventsService.propagateValidateOperation(operation);
  }

  resetOffset(): void {
    this.OFFSET = 0;
  }

  incrementOffset(): void {
    this.OFFSET++;
  }

  public updateOperationState(operation: Operation): void {
    const oldOperation: Operation | undefined = this.state?.operations?.find((op) => op.id === operation.id);
    if (oldOperation) {
      const newOperation: Operation = this.optimisticSourceDocumentOperationService.getNewOperationToReplace(
        oldOperation,
        operation,
      );
      const newOperations: Operation[] = (
        this.state?.operations?.map((op: Operation) => (op.id === newOperation?.id ? newOperation : op)) ?? []
      ).sort((a, b) => this.sortOperations(a, b));
      this.setState({ operations: newOperations } as Partial<
        T & {
          operations: Operation[];
        }
      >);
    }
  }

  protected removeOperationState(operation: Operation): void {
    const newOperations: Operation[] = (
      this.state?.operations?.filter((op: Operation) => op.id !== operation?.id) ?? []
    ).sort((a, b) => this.sortOperations(a, b));
    this.setState({ operations: newOperations } as Partial<
      T & {
        operations: Operation[];
      }
    >);
  }

  protected addOperationState(operation: Operation): void {
    if (!this.operationExistInState(operation)) {
      const newOperations: Operation[] = (
        this.state?.operations?.length ? [...this.state.operations, operation] : [operation]
      ).sort((a, b) => this.sortOperations(a, b));
      this.setState({
        operations: newOperations,
      } as Partial<
        T & {
          operations: Operation[];
        }
      >);
    }
  }

  // TODO Voir pour le remettre dans l'interceptor
  protected showErrorMessage(e: HttpErrorResponse): void {
    if (e.error?.messageCodeInstance?.userMessage) {
      this.flashMessagesService.show(e.error?.messageCodeInstance?.userMessage, {
        timeout: 5000,
        type: 'error',
      });
    }
  }

  protected async handleUpdateError(
    e: unknown,
    operation: Operation,
    breakdown?: Breakdown,
  ): Promise<Operation | null> {
    if (
      (e as HttpErrorResponse).headers.get(X_HEADER.MESSAGE_CODE) ===
        'accountingLine.lockedByDateWithAccountingNumber' &&
      this.userStateService.loggedInUser.isAccountantOrAdmin
    ) {
      const forcedOperation: Operation | null = await this.forceUpdateToUnlockLedger(operation, breakdown);
      return forcedOperation;
    } else if (e instanceof HttpErrorResponse) {
      this.showErrorMessage(e);
    }
    this.logger.error(e);
    return null;
  }

  protected sortOperations(a: Operation, b: Operation): number {
    return new Date(b.date).getTime() - new Date(a.date).getTime() || b.id - a.id;
  }

  private async uploadFiles(operation: Operation, files: File[] | undefined): Promise<Attachment[] | null> {
    return files
      ? await Promise.all(
          files.map((file: File) =>
            lastValueFrom(this.operationHttpService.uploadFile(file, {}, operation.companyId, operation.id)),
          ),
        )
      : null;
  }

  operationExistInState(operation: Operation): boolean {
    return !!this.state?.operations?.find((operationIterated) => operationIterated.id === operation.id);
  }

  private async forceUpdateToUnlockLedger(operation: Operation, breakdown?: Breakdown): Promise<Operation | null> {
    const force: boolean = await lastValueFrom(
      this.modalService
        .open(ConfirmationModalComponent, {
          data: {
            title: 'Confirmation',
            body: "Certains comptes impactés par la modification de cette opération sont verrouillés, il est nécessaire de les déverrouiller pour poursuivre la mise à jour de l'opération. Souhaitez-vous les déverrouiller ?",
            yesText: 'Oui, déverouiller les comptes',
            noText: 'Non, annuler la modification',
            secondaryColor: true,
          },
        })
        .afterClosed$.pipe(map((result) => !!result.data)),
    );
    if (force) {
      return await this.updateOperation(operation, breakdown, true);
    }
    return null;
  }
}
