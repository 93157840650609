import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '@dougs/core/config';
import { AbstractFileHttpService, SourceDocumentAttachment, SourceDocumentType } from '@dougs/core/files';
import { Job } from '@dougs/core/job-dto';
import { AccountingSearch, Breakdown, Operation, OperationPost, SLOT_NAME } from '@dougs/operations/dto';
import { SalesInvoice } from '@dougs/sales-invoice/dto';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';
import { SearchService } from '../services/search.service';

export interface SourceDocumentAttachmentAndJob {
  attachment: SourceDocumentAttachment;
  job: any;
}

@Injectable({
  providedIn: 'root',
})
export class OperationHttpService extends AbstractFileHttpService {
  constructor(
    protected readonly http: HttpClient,
    private readonly searchService: SearchService,
  ) {
    super();
  }

  protected sortedFileModelNameArray: string[] = ['companies', 'operations'];

  getOperationCount(companyId: number, validated: boolean): Observable<number> {
    const params: HttpParams = new HttpParams().append('validated', validated);
    return this.http.get<number>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/operations/count-operations`,
      {
        params,
      },
    );
  }

  getOperations(
    companyId: number,
    limit: number,
    offset: number,
    validated = false,
    needsAttention = false,
    search?: AccountingSearch,
    disableValidated?: boolean,
  ): Observable<Operation[]> {
    let params: HttpParams = new HttpParams().append('limit', limit.toString()).append('offset', offset.toString());

    if (needsAttention) {
      params = params.append('needsAttention', 'true');
    } else if (!disableValidated) {
      params = params.append('validated', validated.toString());
      params = params.append('needsAttention', 'false');
    }
    if (disableValidated && search?.autogenerated === undefined) {
      params = params.append('autogenerated', true);
    }

    if (search) {
      params = params.appendAll(this.searchService.buildSearchHttpParams(search));
    }

    return this.http.get<Operation[]>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/operations`, {
      params,
    });
  }

  getEcommerceIncomingSalesOperations(companyId: number): Observable<Operation[]> {
    return this.http.get<Operation[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/operations/future-dispatch`,
    );
  }

  getVatOperations(
    companyId: number,
    limit: number,
    offset: number,
    validated = false,
    needsAttention = false,
    search?: AccountingSearch,
    disableValidated?: boolean,
  ): Observable<Operation[]> {
    let params: HttpParams = new HttpParams().append('limit', limit.toString()).append('offset', offset.toString());
    if (needsAttention) {
      params = params.append('needsAttention', 'true');
    } else if (!disableValidated) {
      params = params.append('validated', validated.toString());
      params = params.append('needsAttention', 'false');
    }
    if (disableValidated && search?.autogenerated === undefined) {
      params = params.append('autogenerated', true);
    }
    if (search) {
      params = params.appendAll(this.searchService.buildSearchHttpParams(search));
    }
    return this.http.get<Operation[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/operations/get-vat-operations`,
      {
        params,
      },
    );
  }

  getInvoices(
    companyId: number,
    operation: Operation,
    limit: number,
    offset: number,
    search: string,
    operationType: string[] = [],
    isInbound = true,
    candidates?: { associationType: string; slotType: string; breakdownId: number },
  ): Observable<Operation[]> {
    if (
      operationType.includes(SLOT_NAME.INVOICE_CUSTOMER) ||
      operationType.includes(SLOT_NAME.INVOICE_SUPPLIER) ||
      operationType.includes(SLOT_NAME.INVOICE_CUSTOMER_CREDIT_NOTE_OR_OVER_PAYMENT) ||
      operationType.includes(SLOT_NAME.INVOICE_SUPPLIER_CREDIT_NOTE_OR_OVER_PAYMENT)
    ) {
      let invoiceCustomerParams: HttpParams = new HttpParams()
        .append('limit', limit.toString())
        .append('offset', offset.toString())
        .append('q', search)
        .append('id', `$ne$${operation.id}`)
        .append('isInbound', isInbound ? 'true' : 'false')
        .appendAll({ type: operationType });

      if (candidates) {
        invoiceCustomerParams = invoiceCustomerParams.append('candidates', JSON.stringify(candidates));
      }

      return this.http.get<Operation[]>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/operations`, {
        params: invoiceCustomerParams,
      });
    }

    let params: HttpParams = new HttpParams()
      .append('limit', limit.toString())
      .append('offset', offset.toString())
      .append('operationIdToAssociateTo', operation.id);

    if (search !== '') {
      params = params.append('search', search);
    }

    return this.http.get<Operation[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/operations/get-client-invoices`,
      {
        params,
      },
    );
  }

  getCreditNotes(
    companyId: number,
    operation: Operation,
    limit: number,
    offset: number,
    operationType: string[],
    isInbound: boolean,
    search: string,
    candidates?: { associationType: string; slotType: string; breakdownId: number },
  ): Observable<Operation[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('limit', limit.toString());
    params = params.set('offset', offset.toString());
    params = params.appendAll({ type: operationType });
    params = params.set('isInbound', isInbound ? 'true' : 'false');
    params = params.set('q', search);
    params = params.set('id', `$ne$${operation.id}`);

    if (
      (operationType.includes(SLOT_NAME.INVOICE_CUSTOMER) ||
        operationType.includes(SLOT_NAME.INVOICE_SUPPLIER) ||
        operationType.includes(SLOT_NAME.INVOICE_CUSTOMER_CREDIT_NOTE_OR_OVER_PAYMENT) ||
        operationType.includes(SLOT_NAME.INVOICE_SUPPLIER_CREDIT_NOTE_OR_OVER_PAYMENT)) &&
      candidates
    ) {
      params = params.append('candidates', JSON.stringify(candidates));
    }

    return this.http.get<Operation[]>(`${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/operations`, {
      params,
    });
  }

  createOperation(companyId: number, operation: OperationPost): Observable<Operation> {
    return this.http.post<Operation>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/operations`,
      operation,
    );
  }

  createManualOperation(companyId: number, operation: OperationPost): Observable<Operation> {
    return this.http.post<Operation>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/operations/manual-bank-transaction`,
      operation,
    );
  }

  updateOperation(operation: Operation, breakdown?: Breakdown, force?: boolean): Observable<Operation> {
    // TODO Passer par les params
    return this.http.post<Operation>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${operation.companyId}/operations/${operation.id}${
        force ? '?force=true' : ''
      }`,
      { ...operation, updatedBreakdown: breakdown },
    );
  }

  getOperationById(companyId: number, operationId: number): Observable<Operation> {
    return this.http.get<Operation>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/operations/${operationId}`,
    );
  }

  deleteOperation(operation: Operation): Observable<void> {
    return this.http.delete<void>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${operation.companyId}/operations/${operation.id}`,
    );
  }

  postAmazonTaxReportOperationFile(operation: Operation, file: File): Observable<SourceDocumentAttachmentAndJob> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post<SourceDocumentAttachmentAndJob>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${operation.companyId}/operations/${operation.id}/amazonTaxReport/add?filename=${file.name}`,
      formData,
    );
  }

  validateEcommerceCollectionOperations(companyId: number, search?: AccountingSearch): Observable<Job> {
    let params: HttpParams = new HttpParams()
      .append('orderBy', '["date", "DESC"]')
      .append('orderBy', '["transaction","releaseDate","DESC"]')
      .append('orderBy', '["createdAt","DESC"]')
      .append('validated', 'false')
      .append('needsAttention', 'false');

    if (search) {
      params = params.appendAll(this.searchService.buildSearchHttpParams(search));
    }

    return this.http.post<Job>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/operations/validate-ecommerce-collection`,
      params,
    );
  }

  autoCategorizeOperations(companyId: number, operationId: number): Observable<Operation[]> {
    return this.http.get<Operation[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/operations/${operationId}/actions/autocategorize-matching-operations`,
    );
  }

  autoAssociateOperations(companyId: number, operationId: number): Observable<Operation[]> {
    return this.http.get<Operation[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/operations/${operationId}/actions/auto-associate-matching-operations`,
    );
  }

  deleteDispatchOperationBreakdowns(companyId: number, operationId: number): Observable<Operation> {
    return this.http.delete<Operation>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/operations/${operationId}/breakdowns`,
    );
  }

  buildBreakdowns(companyId: number, operationId: number, linesData: string): Observable<Operation> {
    return this.http.post<Operation>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${companyId}/operations/${operationId}/actions/build-breakdowns`,
      {
        linesData,
      },
    );
  }

  getReceiptOperations(vendorInvoice: VendorInvoice): Observable<Operation[]> {
    return this.http.get<Operation[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${vendorInvoice.companyId}/operations/for-receipt/${vendorInvoice.receiptId}`,
    );
  }

  getSourceDocumentOperations(salesInvoice: SalesInvoice): Observable<Operation[]> {
    const params: HttpParams = new HttpParams().append('sourceDocumentId', salesInvoice.sourceDocumentId?.toString());
    return this.http.get<Operation[]>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${salesInvoice.companyId}/operations`,
      { params },
    );
  }

  uploadSourceDocuments(operation: Operation, files: File[], type?: SourceDocumentType): Observable<Operation> {
    const formData: FormData = new FormData();
    if (type) {
      formData.append('type', type);
    }
    files.forEach((file) => {
      formData.append('file', file);
    });
    return this.http.post<Operation>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${operation.companyId}/operations/${operation.id}/source-document-attachments/actions/create-from-formdata`,
      formData,
    );
  }

  attachSourceDocument(
    operation: Operation,
    sourceDocumentId: number,
    sourceDocumentType?: SourceDocumentType,
  ): Observable<Operation> {
    const params: HttpParams = new HttpParams().append('sourceDocumentId', sourceDocumentId?.toString());
    if (sourceDocumentType) {
      params.append('type', sourceDocumentType);
    }
    return this.http.post<Operation>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${operation.companyId}/operations/${operation.id}/source-document-attachments`,
      params,
    );
  }

  detachSourceDocument(operation: Operation, sourceDocumentAttachmentId: number): Observable<Operation> {
    return this.http.delete<Operation>(
      `${AppConfig.settings.legacyApiServerUrl}/companies/${operation.companyId}/operations/${operation.id}/source-document-attachments/${sourceDocumentAttachmentId}`,
    );
  }
}
