import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { Operation } from '@dougs/operations/dto';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';
import { AbstractOperationsStateService } from './abstract-operations-state.service';

@Injectable({
  providedIn: 'root',
})
export class VendorInvoiceOperationsStateService extends AbstractOperationsStateService<{
  matchedOperation?: Operation;
}> {
  public readonly matchedOperation$: Observable<Operation | undefined> = this.select((state) => state.matchedOperation);

  constructor() {
    super();
    this.operationsEventsService.validateOperation$.subscribe((operation: Operation) => {
      this.updateOperationState(operation);
    });
  }

  async refreshOperations(vendorInvoice: VendorInvoice): Promise<void> {
    try {
      const operations: Operation[] = await lastValueFrom(
        this.operationHttpService.getReceiptOperations(vendorInvoice),
      );
      this.setState({
        operations: [...operations].sort((a, b) => this.sortOperations(a, b)),
      });
    } catch (e) {
      this.logger.error(e);
    }
  }

  async refreshMatchedOperation(companyId: number, matchedOperationId: number): Promise<Operation | null> {
    try {
      const operation: Operation = await this.refreshOperationById(companyId, matchedOperationId);
      this.setState({
        matchedOperation: operation,
      });
      return operation;
    } catch (e) {
      this.logger.error(e);
      return null;
    }
  }

  resetState(): void {
    this.setState({
      operations: [],
      matchedOperation: undefined,
    });
  }

  resetMatchedOperation(): void {
    this.setState({
      matchedOperation: undefined,
    });
  }

  shouldOperationBeAddedToState(): boolean {
    return true;
  }

  public removeOperationState(operation: Operation): void {
    super.removeOperationState(operation);
    if (this.state.matchedOperation?.id === operation.id) {
      this.setState({
        matchedOperation: undefined,
      });
    }
  }

  public addOperationState(operation: Operation): void {
    super.addOperationState(operation);
  }

  public updateOperationState(operation: Operation): void {
    super.updateOperationState(operation);
    if (this.state?.matchedOperation?.id === operation?.id) {
      this.setState({
        matchedOperation: operation,
      });
    }
  }
}
